import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import CommonButton from '../button/CommonButton';
import Icon from '../../shared/icomoon';

function CollapseFilterList({
  filterList,
  filterHeader,
  getSelectedFilters,
  checkSelected,
  handleShowHideFilters,
  showButton,
  dropdownOpen = false, // Control if the header is shown
  hideHeader = false // New prop to hide the header
}) {
  const [show, setShow] = useState(dropdownOpen || true);
  
  const toggle = () => setShow(!show);
  
  // Filter the list based on showButton state
  const itemsToDisplay = React.useMemo(() => {
    if (!filterList) return [];
    
    if (showButton || filterList.length <= 40) {
      return filterList;
    }
    
    return filterList.slice(0, 40);
  }, [filterList, showButton]);
  
  return (
    <div>
      <div id="accordion">
        <div className="card">
          {!hideHeader && (
            <div
              className="card-header"
              id="headingOne"
            >
              <h5 className="mb-0">
                <button
                  type="button"
                  className="accordion-button"
                  onClick={toggle}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    {filterHeader}
                    <Icon
                      icon="downarrow2"
                      size={16}
                      color="#1e1e1e"
                    />
                  </div>
                </button>
              </h5>
            </div>
          )}
          
          <Collapse isOpen={show}>
            <div
              id={filterHeader}
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="card-body">
                {itemsToDisplay.map((item) => {
                  // Check if item is a string or object (with value/label properties)
                  const itemValue = typeof item === 'object' ? item.value : item;
                  const itemLabel = typeof item === 'object' ? item.label : item;
                  const isCheckboxSelected = checkSelected.includes(itemValue);
                  
                  return (
                    <div
                      className="d-flex"
                      key={filterHeader + itemValue}
                    >
                      <label
                        htmlFor={filterHeader + itemValue}
                        className="d-flex"
                      >
                        <input
                          type="checkbox"
                          checked={isCheckboxSelected}
                          className="mr-2"
                          onChange={(e) =>
                            getSelectedFilters(e, filterHeader)
                          }
                          name={itemValue}
                          id={filterHeader + itemValue}
                        />
                        {itemLabel}
                      </label>
                    </div>
                  );
                })}
                {filterList && filterList.length > 40 && (
                  <CommonButton
                    variant="secondary"
                    label={!showButton ? 'Show More' : 'Show Less'}
                    customClass="border-0"
                    clickHandler={() => handleShowHideFilters(filterHeader.toLowerCase())}
                  />
                )}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

export default CollapseFilterList;