/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import CommonButton from '../button/CommonButton';
import Icon from '../../shared/icomoon';
import CollapseFilterList from './CollapseFilterList';

class CommonAside extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Industry: false,
      Region: false,
      Experience: false,
      filtersHeight: `${
        (document?.getElementById('HeaderWrap')?.offsetHeight || 170) + 130
      }px`,
    };
    this.handleDropDown = this.handleDropDown.bind(this);
    this.getHeaderHeight = this.getHeaderHeight.bind(this);
  }

  componentDidMount() {
    const resizeObserver = new ResizeObserver(() => {
      const headeHt = document?.getElementById('HeaderWrap')?.offsetHeight;
      this.setState({
        filtersHeight: `${headeHt + 130}px`,
      });
    });
    resizeObserver.observe(document.getElementById('HeaderWrap'));
  }

  handleDropDown(filterClicked) {
    this.setState((prev) => ({
      [filterClicked]: !prev[filterClicked],
    }));
  }

  getHeaderHeight() {
    const headeHt = document?.getElementById('HeaderWrap')?.offsetHeight;
    console.log('headeHt', headeHt);
    return headeHt;
  }

  render() {
    const {
      setFilterToggle,
      filterIndustry,
      filterLevel,
      filterExperience,
      getSelectedFilters,
      selectedIndustryArr,
      selectedLevelArr,
      selectedExperienceArr,
      clearAllFilter,
      handleShowHideIndFilter,
      industryFilterShow,
      levelFilterShow,
      handleShowHideLevelFilter,
      experienceFilterShow,
      handleShowHideExperienceFilter,
    } = this.props;
    
    const {
      openFilter,
      Industry,
      Level,
      Experience,
      filtersHeight,
    } = this.state;
    
    console.log('filtersHeight', filtersHeight);
    
    const commonProps = {
      openFilter,
      getSelectedFilters,
      handleDropDown: this.handleDropDown,
    };
    
    return (
      <div className="aside-filter">
        <div className="aside-sec">
          <div className="aside-header">
            <h4>Filter by</h4>
            <button
              className="cstm-close-button"
              type="button"
              onClick={setFilterToggle}
            >
              <Icon
                icon="deselect"
                size={10}
                color="#1e1e1e"
              />
            </button>
          </div>
          <div
            className="aside-form"
            style={{ height: `calc(100% - ${filtersHeight})` }}
          >
            <div>
              <CollapseFilterList
                {...commonProps}
                filterList={filterIndustry}
                filterHeader="Preferred Industry"
                dropdownOpen={Industry}
                checkSelected={selectedIndustryArr}
                showButton={industryFilterShow}
                handleShowHideFilters={handleShowHideIndFilter}
              />
              <CollapseFilterList
                {...commonProps}
                filterList={filterExperience}
                filterHeader="Industry Experience"
                dropdownOpen={Experience}
                checkSelected={selectedExperienceArr}
                showButton={experienceFilterShow}
                handleShowHideFilters={handleShowHideExperienceFilter}
              />
              <CollapseFilterList
                {...commonProps}
                filterList={filterLevel}
                filterHeader="Level"
                dropdownOpen={Level}
                checkSelected={selectedLevelArr}
                showButton={levelFilterShow}
                handleShowHideFilters={handleShowHideLevelFilter}
              />
            </div>
          </div>
          <div className="aside-clear">
            <CommonButton
              label="Clear All"
              variant="secondary"
              customClass="border-0"
              clickHandler={clearAllFilter}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CommonAside;