import React, { useState, useEffect } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import filterIcon from '../../../assets/images/filter-list-black.png';
import { renderFilters, renderTooltip, renderDateFilter } from '../Utilities';
import CommonButton from '../button/CommonButton';
import SearchField from '../SearchField';
import CollapseFilterList from '../aside/CollapseFilterList';
import Icon from '../../shared/icomoon';

function CommonFilters({
  setFilterToggle,
  searchValue,
  handleSearchInput,
  selectedFunctionArr,
  selectedIndustryArr,
  selectedStatusArr,
  selectedRegionArr,
  selectedLevelArr,
  selectedExperienceArr,
  clearFilters,
  filterCount,
  handleNewest,
  // Props for filter dropdowns
  filterStage,
  filterList,
  filterRegion,
  getSelectedFilters,
}) {
  // Modified to track selected date filter items
  const [selectedDateArr, setSelectedDateArr] = useState([]);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [functionDropdownOpen, setFunctionDropdownOpen] = useState(false);
  const [RegionDropdownOpen, setRegionDropdownOpen] = useState(false);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  
  // Added state variables for the "show more"/"view less" functionality
  const [functionFilterShow, setFunctionFilterShow] = useState(false);
  const [stageFilterShow, setStageFilterShow] = useState(false);
  const [RegionFilterShow, setRegionFilterShow] = useState(false);

  // Date filter options
  const dateFilterOptions = [
    { id: 'all', name: 'Date Added', value: 'all', label: 'Date Added' },
    { id: '30', name: 'Date Added (past 30 days)', value: '30', label: 'Date Added (past 30 days)' },
    { id: '60', name: 'Date Added (past 90 days)', value: '60', label: 'Date Added (past 90 days)' },
    { id: 'year', name: 'Date Added (this year)', value: 'year', label: 'Date Added (this year)' }
  ];

  useEffect(() => {
    // Handle clicks outside the dropdowns to close them
    const handleClickOutside = (event) => {
      if (!event.target.closest('.filter-container') && 
          !event.target.closest('.dropdown-menu')) {
        setStatusDropdownOpen(false);
        setFunctionDropdownOpen(false);
        setRegionDropdownOpen(false);
        setDateDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Wrapper for the new date filter handling
  const handleDateFilterSelection = (selectedFilters, filterHeader) => {
    if (filterHeader === 'Date') {
      console.log('Raw selectedFilters received:', selectedFilters);
      
      let dateFilter = null;
      
      // Handle various formats that might come from CollapseFilterList
      if (typeof selectedFilters === 'string') {
        // If it's just a string ID
        const [matchingFilter] = dateFilterOptions.filter(option => 
          option.id === selectedFilters || option.value === selectedFilters
        );
        dateFilter = matchingFilter || null;
      } 
      else if (Array.isArray(selectedFilters)) {
        if (selectedFilters.length > 0) {
          if (typeof selectedFilters[0] === 'string') {
            // Array of IDs
            const [matchingFilter] = dateFilterOptions.filter(option => 
              option.id === selectedFilters[0] || option.value === selectedFilters[0]
            );
            dateFilter = matchingFilter || null;
          } else {
            // Array of objects
            const [firstItem] = selectedFilters;
            dateFilter = firstItem;
          }
        }
      } 
      else if (selectedFilters && typeof selectedFilters === 'object') {
        // Single object
        dateFilter = selectedFilters;
      }
      
      console.log('Processed dateFilter:', dateFilter);
      
      // Update selected date array
      setSelectedDateArr(dateFilter ? [dateFilter] : []);
      
      // Close the dropdown after selection
      setDateDropdownOpen(false);
      
      // Apply the filter logic
      if (!dateFilter) {
        handleNewest(null);
      } else if (dateFilter.value === 'all' || dateFilter.id === 'all') {
        handleNewest(null);
      } else if (dateFilter.value === 'year' || dateFilter.id === 'year') {
        const currentYear = new Date().getFullYear();
        const startOfYear = new Date(currentYear, 0, 1);
        const daysSinceStartOfYear = Math.ceil((new Date() - startOfYear) / (1000 * 60 * 60 * 24));
        handleNewest(daysSinceStartOfYear);
      } else {
        const value = dateFilter.value || dateFilter.id;
        handleNewest(parseInt(value, 10));
      }
      
      console.log('Selected date filter:', dateFilter);
      console.log('Setting newest to:', dateFilter ? (dateFilter.value || dateFilter.id) : 'null');
    }
  };

  const handleDropdownToggle = (dropdown, currentState) => {
    // Close all dropdowns
    if (dropdown === 'status') {
      setStatusDropdownOpen(!currentState);
      setFunctionDropdownOpen(false);
      setRegionDropdownOpen(false);
      setDateDropdownOpen(false);
    } else if (dropdown === 'function') {
      setStatusDropdownOpen(false);
      setFunctionDropdownOpen(!currentState);
      setRegionDropdownOpen(false);
      setDateDropdownOpen(false);
    } else if (dropdown === 'region') {
      setStatusDropdownOpen(false);
      setFunctionDropdownOpen(false);
      setRegionDropdownOpen(!currentState);
      setDateDropdownOpen(false);
    } else if (dropdown === 'date') {
      setStatusDropdownOpen(false);
      setFunctionDropdownOpen(false);
      setRegionDropdownOpen(false);
      setDateDropdownOpen(!currentState);
    }
  };

  const handleKeyDown = (e, dropdown, currentState) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleDropdownToggle(dropdown, currentState);
    }
  };

  // Handle show more/less for Function filter
  const handleShowHideFilters = () => {
    setFunctionFilterShow(!functionFilterShow);
  };

  // Handle show more/less for Status filter
  const handleShowHideStageFilter = () => {
    setStageFilterShow(!stageFilterShow);
  };

  // Handle show more/less for Level filter
  const handleShowHideRegionFilter = () => {
    setRegionFilterShow(!RegionFilterShow);
  };

  // Clear Date Filter
  const clearDateFilter = (filterName) => {
    console.log('Clearing date filter:', filterName);
    setSelectedDateArr([]);
    handleNewest(null);
  };

  // Custom renderFilters function is not needed anymore
  // We're using the renderDateFilter from Utilities instead

  // Check for date filter to determine if it should be displayed in the button
  const getDateLabel = () => {
    if (selectedDateArr && selectedDateArr.length > 0 && selectedDateArr[0]) {
      const dateItem = selectedDateArr[0];
      return dateItem.name || dateItem.label || 'Date Added';
    }
    return 'Date Added';
  };

  // Fixed width for dropdown containers
  const filterWidth = 180;
  const dropdownWidth = 320;
  const filterHeight = 35;

  return (
    <div className="siteFilter d-flex align-items-top col-gap search pt-3 flex-wrap">
      <SearchField
        value={searchValue}
        onChange={handleSearchInput}
        placeholder="Search for anything"
        className="search-field"
      />
      
      {/* Status Filter */}
      <div className="filter-container position-relative" style={{ width: `${filterWidth}px`, height: `${filterHeight}px` }}>
        <button 
          className="filter-dropdown-toggle form-control d-flex align-items-center justify-content-between" 
          style={{ cursor: 'pointer', height: '35px', textAlign: 'left', width: '100%' }}
          onClick={() => handleDropdownToggle('status', statusDropdownOpen)}
          onKeyDown={(e) => handleKeyDown(e, 'status', statusDropdownOpen)}
          type="button"
          aria-haspopup="true"
          aria-expanded={statusDropdownOpen}
        >
          <span className="filter-label">{selectedStatusArr.length > 0 ? `Status (${selectedStatusArr.length})` : 'Status'}</span>
          <Icon
            icon="downarrow2"
            size={16}
            color="#1e1e1e"
            className={statusDropdownOpen ? 'rotate-180' : ''}
          />
        </button>
        {statusDropdownOpen && (
          <div className="position-absolute bg-white border rounded shadow-sm z-index-dropdown" 
            style={{
              zIndex: 100, 
              maxHeight: '300px', 
              overflowY: 'auto',
              width: `${dropdownWidth}px`,
              left: '0'
            }}>
            <div className="p-2">
              <CollapseFilterList
                getSelectedFilters={getSelectedFilters}
                openFilter={true}
                filterList={filterStage} 
                filterHeader="Status"
                dropdownOpen={true}
                checkSelected={selectedStatusArr}
                handleDropDown={() => {}}
                showButton={stageFilterShow}
                handleShowHideFilters={handleShowHideStageFilter}
                hideHeader={true}
              />
            </div>
          </div>
        )}
      </div>
      
      {/* Function Filter */}
      <div className="filter-container position-relative" style={{ width: `${filterWidth}px` }}>
        <button 
          className="filter-dropdown-toggle form-control d-flex align-items-center justify-content-between" 
          style={{ cursor: 'pointer', height: '35px', textAlign: 'left', width: '100%' }}
          onClick={() => handleDropdownToggle('function', functionDropdownOpen)}
          onKeyDown={(e) => handleKeyDown(e, 'function', functionDropdownOpen)}
          type="button"
          aria-haspopup="true"
          aria-expanded={functionDropdownOpen}
        >
          <span className="filter-label">{selectedFunctionArr.length > 0 ? `Function (${selectedFunctionArr.length})` : 'Function'}</span>
          <Icon
            icon="downarrow2"
            size={16}
            color="#1e1e1e"
            className={functionDropdownOpen ? 'rotate-180' : ''}
          />
        </button>
        {functionDropdownOpen && (
          <div className="position-absolute bg-white border rounded shadow-sm z-index-dropdown" 
            style={{
              zIndex: 100, 
              maxHeight: '300px', 
              overflowY: 'auto',
              width: `${dropdownWidth}px`,
              left: '0'
            }}>
            <div className="p-2">
              <CollapseFilterList
                getSelectedFilters={getSelectedFilters}
                openFilter={true}
                filterList={filterList}
                filterHeader="Function"
                dropdownOpen={true}
                checkSelected={selectedFunctionArr}
                handleDropDown={() => {}}
                showButton={functionFilterShow}
                handleShowHideFilters={handleShowHideFilters}
                hideHeader={true}
              />
            </div>
          </div>
        )}
      </div>
      
      {/* Region Filter */}
      <div className="filter-container position-relative" style={{ width: `${filterWidth}px` }}>
        <button 
          className="filter-dropdown-toggle form-control d-flex align-items-center justify-content-between" 
          style={{ cursor: 'pointer', height: '35px', textAlign: 'left', width: '100%' }}
          onClick={() => handleDropdownToggle('region', RegionDropdownOpen)}
          onKeyDown={(e) => handleKeyDown(e, 'region', RegionDropdownOpen)}
          type="button"
          aria-haspopup="true"
          aria-expanded={RegionDropdownOpen}
        >
          <span className="filter-label">{selectedRegionArr.length > 0 ? `Region (${selectedRegionArr.length})` : 'Region'}</span>
          <Icon
            icon="downarrow2"
            size={16}
            color="#1e1e1e"
            className={RegionDropdownOpen ? 'rotate-180' : ''}
          />
        </button>
        {RegionDropdownOpen && (
          <div className="position-absolute bg-white border rounded shadow-sm z-index-dropdown" 
            style={{
              zIndex: 100, 
              maxHeight: '300px', 
              overflowY: 'auto',
              width: `${dropdownWidth}px`,
              left: '0'
            }}>
            <div className="p-2">
              <CollapseFilterList
                getSelectedFilters={getSelectedFilters}
                openFilter={true}
                filterList={filterRegion}
                filterHeader="Region"
                dropdownOpen={true}
                checkSelected={selectedRegionArr}
                handleDropDown={() => {}}
                showButton={RegionFilterShow}
                handleShowHideFilters={handleShowHideRegionFilter}
                hideHeader={true}
              />
            </div>
          </div>
        )}
      </div>
      
      {/* Date Filter - Completely Revised Implementation */}
      <div className="filter-container position-relative" style={{ width: `${filterWidth}px` }}>
        <button 
          className="filter-dropdown-toggle form-control d-flex align-items-center justify-content-between" 
          style={{ cursor: 'pointer', height: '35px', textAlign: 'left', width: '100%' }}
          onClick={() => handleDropdownToggle('date', dateDropdownOpen)}
          onKeyDown={(e) => handleKeyDown(e, 'date', dateDropdownOpen)}
          type="button"
          aria-haspopup="true"
          aria-expanded={dateDropdownOpen}
        >
          <span 
            className="filter-label"
            style={{
              maxWidth: 'calc(100% - 20px)',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block'
            }}
          >
            {getDateLabel()}
          </span>
          <Icon
            icon="downarrow2"
            size={16}
            color="#1e1e1e"
            className={dateDropdownOpen ? 'rotate-180' : ''}
          />
        </button>
        {dateDropdownOpen && (
          <div className="position-absolute bg-white border rounded shadow-sm z-index-dropdown" 
            style={{
              zIndex: 100, 
              maxHeight: '300px', 
              overflowY: 'auto',
              width: `${dropdownWidth}px`,
              left: '0'
            }}>
            <div className="p-2">
              <div className="filter-options">
                {dateFilterOptions.map((option) => (
                  <div key={option.id} className="d-flex align-items-center mb-2">
                    <input
                      type="checkbox"
                      id={`date-${option.id}`}
                      name="date-filter"
                      checked={selectedDateArr.length > 0 && selectedDateArr[0].id === option.id}
                      onChange={() => handleDateFilterSelection([option], 'Date')}
                      className="mr-2"
                    />
                    <label 
                      htmlFor={`date-${option.id}`} 
                      className="mb-0 ml-2 text-nowrap"
                      style={{ cursor: 'pointer' }}
                    >
                      {option.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      
      {/* All Filters Button */}
      <div>
        <CommonButton
          customClass="rounded-8 border-search pl-4 position-relative filter-btn"
          clickHandler={setFilterToggle}
          label="Other filters"
          variant="secondary"
          iconSrc={filterIcon}
          showBadge={true}
          badge={filterCount || null}
        />
      </div>
      
      {/* Selected Filters Display */}
      <div
        id="filterGroup"
        className="d-flex flex-wrap col-gap"
      >
        {selectedFunctionArr && selectedFunctionArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedFunctionArr)}
            >
              {renderFilters(
                'Function',
                selectedFunctionArr,
                'selectedFunctionArr',
                clearFilters,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
        {selectedIndustryArr && selectedIndustryArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedIndustryArr)}
            >
              {renderFilters(
                'Preferred Industry',
                selectedIndustryArr,
                'selectedIndustryArr',
                clearFilters,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
        {selectedStatusArr && selectedStatusArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedStatusArr)}
            >
              {renderFilters(
                'Status',
                selectedStatusArr,
                'selectedStatusArr',
                clearFilters,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
        {selectedRegionArr && selectedRegionArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedRegionArr)}
            >
              {renderFilters(
                'Region',
                selectedRegionArr,
                'selectedRegionArr',
                clearFilters,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
        {selectedLevelArr && selectedLevelArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedLevelArr)}
            >
              {renderFilters(
                'Level',
                selectedLevelArr,
                'selectedLevelArr',
                clearFilters,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
        {selectedExperienceArr && selectedExperienceArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedExperienceArr)}
            >
              {renderFilters(
                'Industry Experience',
                selectedExperienceArr,
                'selectedExperienceArr',
                clearFilters,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
        {/* Add Date Filter Display */}
        {selectedDateArr && selectedDateArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedDateArr)}
            >
              {renderDateFilter(
                'Date',
                selectedDateArr,
                'selectedDateArr',
                clearDateFilter,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
      </div>
    </div>
  );
}

export default CommonFilters;