/* eslint-disable default-param-last */
import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import closeBtn from '../../assets/images/remove filter option.png';
import Icon from '../shared/icomoon';

const MAX_STRING_LENGTH = 100;

export const renderTooltip = (text) => (
  <Tooltip id="tooltip">
    {text.slice(1).map((item) => (
      <ul
        key={item}
        className="tooltip-ui"
      >
        <li>{item}</li>
      </ul>
    ))}
  </Tooltip>
);

export const renderTooltipSingle = (text) => (
  <Tooltip id="button-tooltip">
        <p className="text-gray-800">{text}</p>
  </Tooltip>
);

export const truncate = (value) => {
  let output = value && value.toString();

  if (output.length > MAX_STRING_LENGTH) {
    output = `${output.substring(0, MAX_STRING_LENGTH)}...`;
  }

  return output;
};

export const truncate1 = (value) => {
  // let output = value?.toString() ?? '';
  let output = value && value.toString();

  if (output.length > MAX_STRING_LENGTH) {
    output = `${output.substring(0, MAX_STRING_LENGTH)}...`;
  }

  return output;
};

export const renderFilters = (
  filterType,
  filterValues,
  filterName,
  clearFilters,
  size
) => (
  <div>
    <span className="filter-type">{filterType}</span>:{' '}
    <span className="filter-value">
      {filterValues.length > 1
        ? `${filterValues[0]}, +${filterValues.length - 1}`
        : filterValues.toString()}
    </span>{' '}
    <span className="filter-separator"> |</span>{' '}
    <button
      name={filterName}
      className="cstm-close-button"
      type="button"
      onClick={() => clearFilters(filterName)}
    >
      <img
        src={closeBtn}
        height={size}
        width={size}
        alt=""
      />
    </button>
  </div>
);

export const renderDateFilter = (
  filterType,
  filterValues,
  filterName,
  clearFilters,
  size = 10
) => {
  // Get the display text from the filter object
  const displayText = filterValues && filterValues.length > 0 
    ? (filterValues[0].name || filterValues[0].label || 'Date Filter')
    : 'Date Filter';
  
  return (
    <div>
      <span className="filter-type">{filterType}</span>:{' '}
      <span className="filter-value">{displayText}</span>{' '}
      <span className="filter-separator"> |</span>{' '}
      <button
        name={filterName}
        className="cstm-close-button"
        type="button"
        onClick={() => clearFilters(filterName)}
      >
        <img
          src={closeBtn}
          height={size}
          width={size}
          alt=""
        />
      </button>
    </div>
  );
};

export const renderAdditionalCount = (count = 1) => (
  <div className="addt-cnt rounded-circle mx-2">{`+${count}`}</div>
);

// eslint-disable-next-line default-param-last
export const renderUserInfoTab = (
  email = '',
  name = '',
  size = 30,
  userAvatar
) => (
  <div className="d-flex dropdown-initial">
    {userAvatar && !userAvatar.avatar && (
      <div className="initials">{userAvatar.initials}</div>
    )}

    {userAvatar && userAvatar.avatar && (
      <img
        className="rounded-circle profile-img"
        src={userAvatar.avatar}
        width={size}
        height={size}
        alt="HH"
      />
    )}
    <div>
      <p className="username">{name}</p>
      <p className="useremail">{email}</p>
    </div>
  </div>
);

export const renderContacts = (name, email = 'defaultName') => (
  <div
    key={`${name}${email}`}
    className="d-flex m-1 align-items-center render-cont"
    name={email}
  >
    <Icon
      icon="contact"
      size={20}
      color="textColorBlkshade"
      className="rounded-circle"
    />{' '}
    {name}
  </div>
);

export const createInitials = (string) => {
  const words = string.split(' ');
  const capitalize = words.map((item) => {
    const firstWord = item.charAt(0).toUpperCase();
    return firstWord;
  });
  return capitalize.join('');
};

export const renderAvatar = (user = {}) => {
  let userInfo;
  if (Object.keys(user).length > 0) {
    userInfo = {
      avatar: user.avatar,
      initials: createInitials(`${user.first_name} ${user.last_name}`),
    };
  }
  return userInfo;
};

export const getFilters = (event, selectedFilter) => {
  const {
    selectedFunctionArr,
    selectedIndustryArr,
    selectedStatusArr,
    selectedRegionArr,
    selectedCheckboxes,
    selectedLevelArr,
    selectedContactsArr,
  } = this.state;
  let selectedChkBx;
  if (selectedFilter === 'Function') selectedChkBx = selectedFunctionArr;
  else if (selectedFilter === 'Industry') selectedChkBx = selectedIndustryArr;
  else if (selectedFilter === 'Status') selectedChkBx = selectedStatusArr;
  else if (selectedFilter === 'Region') selectedChkBx = selectedRegionArr;
  else if (selectedFilter === 'Level') selectedChkBx = selectedLevelArr;
  else if (selectedFilter === 'Contact') selectedChkBx = selectedContactsArr;
  else selectedChkBx = selectedCheckboxes;

  const checkbox = event.target;
  const checkboxValue = checkbox.name;

  if (checkbox.checked) {
    selectedChkBx.push(checkboxValue);
    this.setState({ selectedCheckboxes: selectedChkBx }, () => {
      if (selectedFilter !== 'Contact') {
        this.getSearchClient();
      }
    });
  } else {
    const index = selectedChkBx.indexOf(checkboxValue);
    if (index > -1) {
      selectedChkBx.splice(index, 1);
      this.setState({ selectedCheckboxes: selectedChkBx }, () => {
        if (selectedFilter !== 'Contact') {
          this.getSearchClient();
        }
      });
    }
  }
  if (selectedFilter === 'Contact') {
    this.selectedContactsEmails();
  }
};

export const requestInfoModalMesage = () => `Dear [Founder's name],

I hope this email finds you well.

My name is [Your name] and I am the founder of [Your company]. I am writing to you today to request an introduction to [Founder's name].

I am a big fan of [Founder's company] and I have been following your work for some time. I am particularly impressed with your work in [Area of expertise].

I believe that there is a lot of potential for collaboration between our two companies. We are both working on [Similar goals] and I believe that we can learn a lot from each other.

I would be grateful if you could introduce me to [Founder's name]. I would be happy to set up a time to chat about our companies and see if there is any potential for collaboration.

Thank you for your time and consideration.

Sincerely, [Your name]
`;

export const reqInfoSuccessMessage = () => (
  <>
    <h4>Thank You!</h4>
    <p>Someone from our team will reach out soon.</p>
  </>
);

export const reqIntroSuccessMessage = () => (
  <>
    <h4>Thank You!</h4>
    <p>Someone from our team will reach out soon.</p>
  </>
);

export const customStylesReactSelect = {
  control: (provided) => ({
    ...provided,
    minHeight: '2.875rem',
    height: '2.875rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#f8f9fa' : 'white',
    color: state.isSelected && 'black',
  }),
};

export const customThemeReactSelect = (theme) => ({
  ...theme,
  borderRadius: '3px',
  colors: {
    ...theme.colors,
    primary25: '#f8f9fa',
    primary: '#623BA5',
  },
});

export const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '35px',
    height: '35px',
    borderRadius: '8px', // Matching the rounded-8 class
    '&:hover': {
      borderColor: '#dee2e6'
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#f8f9fa' : 'white',
    color: state.isSelected && 'black',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '8px' // Matching the control borderRadius
  })
};

export const customTheme = (theme) => ({
  ...theme,
  borderRadius: '8px', // Updated to match the rounded-8 class
  colors: {
    ...theme.colors,
    primary25: '#f8f9fa',
    primary: '#623BA5',
  },
});

export const customStylesReactSelectFilter = {
  control: (provided) => ({
    ...provided,
    minHeight: '36px',
    height: '36px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F8F9FA' : 'white',
    color: state.isSelected && 'black',
  }),
};

export const generateLinkedInUrl = (url) => {
  let linkedinUrl = url;
  if (linkedinUrl && !linkedinUrl.includes('linkedin.com/in')) {
    linkedinUrl = `https://www.linkedin.com/in/${linkedinUrl}`;
  }
  if (linkedinUrl && !linkedinUrl.includes('http')) {
    linkedinUrl = `https://${linkedinUrl}`;
  }
  return linkedinUrl;
};
